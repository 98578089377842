import { render, staticRenderFns } from "./Service.vue?vue&type=template&id=6841e2a1&scoped=true&"
import script from "./Service.vue?vue&type=script&lang=js&"
export * from "./Service.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6841e2a1",
  null
  
)

export default component.exports