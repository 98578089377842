<template>
  <!-- Start Service Area -->
  <div class="rn-service-area d-flex bg_color--3" id="service">
    <div class="rn-service-left w-50 rn-service-bg-wrapper">
      <v-fade-transition leave-absolute>
        <div
          v-if="activetab === 1"
          class="h-full rn-service-bg-wrapper"
          :style="{ backgroundImage: 'url(' + src1 + ')' }"
        ></div>
      </v-fade-transition>
      <v-fade-transition leave-absolute>
        <div
          v-if="activetab === 2"
          class="h-full rn-service-bg-wrapper"
          :style="{ backgroundImage: 'url(' + src2 + ')' }"
        ></div
      ></v-fade-transition>
      <v-fade-transition leave-absolute>
        <div
          v-if="activetab === 3"
          class="h-full rn-service-bg-wrapper"
          :style="{ backgroundImage: 'url(' + src3 + ')' }"
        ></div
      ></v-fade-transition>
      <v-fade-transition leave-absolute>
        <div
          v-if="activetab === 4"
          class="h-full rn-service-bg-wrapper"
          :style="{ backgroundImage: 'url(' + src4 + ')' }"
        ></div
      ></v-fade-transition>
    </div>

    <div class="rn-service-right w-50 d-flex align-items-center">
      <div class="text-left story-content rn-plr section-ptb-xl">
        <div class="content">
          <h2 class="section-title">Qué ofrezco:</h2>
          <div class="service-wrapper">
            <!-- Start Single Service -->
            <div class="service">
              <h4
                @mouseover="activetab = 1"
                :class="[activetab === 1 ? 'active' : '']"
              >
                <router-link to="/service-details"
                  >LA TÉCNICA: Mis dibujos son a mano alzada, principalmente utilizando la técnica de la plumilla aunque también empleo el color usando acuarela y en ocasiones pastel.</router-link
                >
              </h4>
              <p>EL DIBUJO O PINTURA POR ENCARGO: ¿Buscas un regalo único y personalizado para alguien especial? La idea es ofrecer a mis clientes, dibujos por encargo con dedicatoria incluida. Te puedo ofrecer dibujar aquellos lugares o temas, que para el destinatario puedan tener una especial significación; su pueblo, su casa de campo, las montañas o las playas que lleva viendo desde que era niño o aquellas vistas de la ciudad de la que guarda un grato recuerdo.</p>
            </div>
            <!-- End Single Service -->

            <!-- Start Single Service -->
            <div class="service">
              <h4
                @mouseover="activetab = 2"
                :class="[activetab === 2 ? 'active' : '']"
              >
                <router-link to="/service-details"
                  >PROYECTOS: Dibujos para bodas y eventos</router-link
                >
              </h4>
              <p>Me atrae sobre todo trabajar en proyectos singulares, como las series de avifauna, temática marina o eventos de especial significado para quien realiza el encargo; invitaciones de bodas, comuniones y bautizos o celebraciones de empresa. Cada dibujo es único y se realiza de acuerdo a las preferencias del cliente.En la galería de ésta página puedes ver algún ejemplo de estos proyectos, como la realización de una larga serie de dibujos realizados para una boda, habiendo sido empleados en la ilustración de las invitaciones, sitting plans, agradecimientos, decoración de mesas y mucho más. 
La temática de los dibujos son aquellos lugares importantes para los novios o para el evento en cuestión, así como motivos específicos que se ajusten al tema de la boda o evento.
</p>
            </div>
            <!-- End Single Service -->

            <!-- Start Single Service -->
            <div class="service">
              <h4
                @mouseover="activetab = 3"
                :class="[activetab === 3 ? 'active' : '']"
              >
                <router-link to="/service-details"
                  >DECORACIÓN: Dibujos para decoración</router-link
                >
              </h4>
              <p>
                Los dibujos a mano pueden ser una excelente y económica opción para decorar interiores de casas, oficinas o despachos. Además de los dibujos por encargo, en la galería se puede ver la oferta disponible de dibujos ya realizados de paisajes y monumentos icónicos y representativos de una ciudad o de sus calles más emblemáticas. Estos dibujos pueden ser de diferentes tamaños y formatos, en color o blanco y negro.
              </p>
            </div>
            <!-- End Single Service -->

            <!-- Start Single Service -->
            <div class="service">
              <h4
                @mouseover="activetab = 4"
                :class="[activetab === 4 ? 'active' : '']"
              >
                <router-link to="/service-details"
                  >ILUSTRACIONES: Ilustración de cuentos infantiles y poemas.</router-link
                >
              </h4>
              <p>
                Si deseas guardar físicamente el recuerdo de aquellos cuentos infantiles o historias que te contaban tus mayores cuando eras niño, también puedo ofrecerte la ilustración de dichos cuentos con su texto correspondiente en cuidada caligrafía. He ilustrado poemas propios (sí escribo algún poema de cuando en cuando) añadiendo a los versos dibujos que hacen referencia a ellos. En la galería puedes ver algún ejemplo de estos trabajos.
              <p>
                Please <router-link to="/contact">Contactanos</router-link>
              </p>
            </div>
            <!-- End Single Service -->
            <!-- Start Single Service -->
            <div class="service">
              <h4
                @mouseover="activetab = 4"
                :class="[activetab === 4 ? 'active' : '']"
              >
                <router-link to="/service-details"
                  >¿Necesitas algo mas?</router-link
                >
               </h4>
              <p>
                Please <router-link to="/contact">Contactanos</router-link>
              </p>
            </div>
            <!-- End Single Service --> 
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End Service Area -->
</template>

<script>
  export default {
    data() {
      return {
        el: "#service",
        activetab: 1,
        src1: require("../../assets/img/service/services-1.jpg"),
        src2: require("../../assets/img/service/services-2.jpg"),
        src3: require("../../assets/img/service/services-3.jpg"),
        src4: require("../../assets/img/service/services-4.jpg"),
      };
    },
  };
</script>

<style lang="scss" scoped>
  .rn-service-area {
    overflow: hidden;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
</style>
